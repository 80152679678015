import React, { useState } from "react";
import { Layout } from "../components";
import { SubpageHeader } from "../components";
import ReactPlayer from "react-player";

const RoiMasters = ({ location: { pathname } }) => {
	const [playingVideo, setPlayingVideo] = useState(null);

	const persons = {
		Person1: {
			key: "PERSON1",
			name: "Patrycja Idzińska",
			job: "CEO & Founder",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/patrycja-idzinska_new.mp4",
			playing: true,
			color: "blue",
			extraBackground: true,
		},
		Person2: {
			key: "PERSON2",
			name: "Łukasz Pach",
			job: "Founder",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/lukasz-pach.mp4",
			playing: false,
			color: "blue",
		},
		Person3: {
			key: "PERSON3",
			name: "Wojciech Kmiecik",
			job: "Founder",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/wojciech-kmiecik.mp4",
			playing: false,
			color: "blue",
		},
		Person4: {},
		// Person5: {
		//   key: "PERSON5",
		//   name: "Ewa Grzejszczyk",
		//   job: "Sales & Customer Success Manager ",
		//   video:
		//     "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/ewa-grzejszczyk.mp4",
		//   playing: true,
		//   color: "blue",
		//   extraBackground: true,
		// },
		Person6: {
			key: "PERSON6",
			name: "Aleksandra Szymańska",
			job: "Customer Success Team Leader",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/aleksandra-szymanska.mp4",
			playing: true,
			color: "blue",
			extraBackground: true,
		},
		Person7: {
			key: "PERSON7",
			name: "Magdalena Jarzębowska",
			job: "Customer Success Specialist",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/magdalena-jarzebowska.mp4",
			playing: false,
			color: "blue",
		},
		Person77: {},
		Person8: {},
		Person9: {
			key: "PERSON9",
			name: "Alicja Hojcak-Pełczyńska",
			job: "PPC Team Leader",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/alicja-hojcak-pelczynska.mp4",
			playing: true,
			color: "violet",
			extraBackground: true,
		},
		Person10: {
			key: "PERSON10",
			name: "Anna Tochowicz",
			job: "Senior PPC Specialist",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/anna-karczewska.mp4",
			playing: false,
			color: "violet",
		},
		Person11: {
			key: "PERSON11",
			name: "Milena Post-Ziółek",
			job: "Senior PPC Specialist",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/milena-post-ziolek.mp4",
			playing: false,
			color: "violet",
		},
		Person12: {
			key: "PERSON12",
			name: "Monika Gawrońska",
			job: "PPC Specialist",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/monika-maciejczyk.mp4",
			playing: false,
			color: "violet",
		},
		Person13: {
			key: "PERSON13",
			name: "Joanna Nacfalska",
			job: "PPC Specialist",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/asia-nacfalska.mp4",
			playing: false,
			color: "violet",
		},
		Person15: {
			key: "PERSON15",
			name: "Bartosz Hybza",
			job: "PPC Specialist",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/bartosz-hybza.mp4",
			playing: false,
			color: "violet",
		},
		Person20: {
			key: "PERSON20",
			name: "Patryk Ryguła",
			job: "PPC Specialist",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/patryk-rygula.mp4",
			playing: false,
			color: "violet",
		},
		Person16: {
			key: "PERSON16",
			name: "Agnieszka Więcław",
			job: "Senior Social Media Specialist",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/agnieszka-wieclaw.mp4",
			playing: false,
			color: "violet",
		},
		Person17: {
			key: "PERSON17",
			name: "Ilona Siemczyk",
			job: "Social Media Specialist",
			video: "https://when.zenx.pl/wp-content/uploads/2024/06/ilona-siemczyk.mp4",
			playing: false,
			color: "violet",
		},
		Person18: {
			key: "PERSON18",
			name: "Dominika Kubacka-Romańczuk",
			job: "Senior Social Media Specialist",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/dominika-kubacka-romanczuk.mp4",
			playing: false,
			color: "violet",
		},
		Person19: {
			key: "PERSON19",
			name: "Agnieszka Sobala",
			job: "Senior Social Media Specialist",
			video: "https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/agnieszka-sobala.mp4",
			playing: false,
			color: "violet",
		},
	};

	return (
		<Layout
			seo={{
				title: "ROI Masters",
				description:
					"Sprawdź, kto będzie częścią sukcesu Twojej współpracy z When. Certyfikowani specjaliści z wieloletnim doświadczeniem w marketingu internetowym.Setki przeprowadzonych kampanii i zadowolonych klientów. 8 lat doświadczenia w PPC.",
				href: pathname,
				lang: "pl",
			}}
		>
			<SubpageHeader title="ROI masters" />
			<section className="roi-section">
				<div className="page-header">
					<h2>
						Sprawdź, kto będzie częścią <br /> sukcesu Twojej
						kampanii
					</h2>
				</div>
				<div className="row no-gutters">
					{Object.values(persons).map((person, index) => {
						return (
							<div
								className={`${
									person.color === "empty"
										? "col-lg-3 hidden-col"
										: "col-lg-3"
								}`}
								key={index}
							>
								<div
									className={`${
										person.extraBackground &&
										person.extraBackground === true
											? "single-person single-person--extra-bg"
											: "single-person"
									}`}
								>
									{person.video ? (
										<div
											className="single-person__avatar-wrapper"
											onMouseEnter={(e) =>
												setPlayingVideo(index)
											}
											onMouseLeave={(e) =>
												setPlayingVideo(null)
											}
											aria-hidden="true"
										>
											<ReactPlayer
												url={person.video}
												width="95%"
												height="100%"
												playing={
													playingVideo === index
														? true
														: person.playing
												}
												muted={true}
												playsinline={true}
												loop={true}
											/>
										</div>
									) : person.photo ? (
										<div className="single-person__avatar-wrapper">
											<img src={person.photo} alt="" />
										</div>
									) : null}

									<h3
										className={`${
											person.color === "blue"
												? "single-person__name single-person__name--blue"
												: person.color === "violet"
												? "single-person__name single-person__name--violet"
												: "single-person__name"
										}`}
									>
										{person.name}
									</h3>
									<div className="single-person__job">
										{person.job}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</section>
		</Layout>
	);
};

export default RoiMasters;
